import {
  KEY_UP,
  KEY_DOWN,
  KEY_RETURN,
  KEY_ESCAPE
} from './constants/keycodes.js'

import {
    getJsonpResponse,
  } from './functions'

import {
    addClickOutsideContainerHandler,
} from '../popups/functions'

export default class {

  constructor(inputElem, suggestionsElem, options={}) {

      this.inputElem = inputElem
      this.suggestionsElem = suggestionsElem

      const $this = this;
      
      options = {
          
          // This is the name of the class to mark a suggestion as selected 
          selectedClass: "suggestions__list-item--selected",
          
          // This is the query for selecting the list element within the suggestions container element
          ulQuerySelector: "ul", 
          
          // This is the handler for opening the suggestions
          openHandler: () => suggestionsElem.style.display = "block",
          
          // This is the handler for closing the suggestions
          closeHandler: () => suggestionsElem.style.display = "none",
          
          // The default behaviour for a suggestion click is to update the inputElem  
          // with the suggestion and submit the form.
          clickHandler: (query, inputElem, suggestionsElem) => {

              // get the form element 
              let form = inputElem
              while(form && form.tagName.toLowerCase() != "form") {
                  form = form.parentNode
              }

              // some form submit buttons have been given id="submit" which has 
              // overwritten the submit() method :( Removing the id removes styles 
              // so had to fetch the method from the prototype.
              const submitFormFunction = Object.getPrototypeOf(form).submit
              submitFormFunction.call(form)

          },

          // this is the suggest api url, needs to be set 
          suggestUrl: null, // e.g. http://funnelback/s/suggest.json?..

          // when words are simply not given by the suggest api we might wanna provide a list 
          // so that they will appear for this instance of Suggestions. For example, 'genealogy' 
          // is missing even thought it is in the indexed pages. I've tried to get an answer from 
          // funnelback but not got a reply yet.
          pushWords: [],

          // this is the default for merging push words in that push words will be inserted to the 
          // start of the array 
          mergePushWordsCallback: (pushWords, suggestions) => suggestions.concat(pushWords),

          // this is the function that determines whether or not a partial query matches a push word 
          getPushWordsMatcher: (partialQuery, word) => word.indexOf(partialQuery) === 0,

          // set this for how many suggestions to return, default is 5 
          limit: 5,

          ...options
      }
      this.options = options

      // object to store the timeout on keyup
      let inputTimeout

      // suggestions
      const suggestionsListElem = suggestionsElem.querySelector( options.ulQuerySelector )

      addClickOutsideContainerHandler(suggestionsElem.parentNode, () => {
          options.closeHandler()
      })

      /**
       * This will de-select the others, and select the nextSelectedItem only
       * @param {DOMElem} suggestionsElem The suggestion to select
       */
      const selectSuggestion = (suggestionsElem) => {

          // first remove class from all list items
          for (let li of suggestionsListElem.querySelectorAll("li")) {
            li.classList.remove( options.selectedClass )
          }

          // if we were able to location the next selected, attach class
          suggestionsElem && suggestionsElem.classList.add( options.selectedClass )
      }

      let inputValue

      // as the user types, we'll fetch other suggestions
      inputElem.addEventListener("keyup", function(e) {

          // TODO import keys 
          switch (e.keyCode) {
              case KEY_UP:
              case KEY_DOWN:

                  // if suggestions is not visible then nothing to do here
                  let isSuggestionsVisible = suggestionsElem.offsetWidth > 0 && suggestionsElem.offsetHeight > 0
                  if (isSuggestionsVisible) {

                      // get the selected suggestions (if any, null otherwise)
                      let selectedListItem = suggestionsElem.querySelector("li."+options.selectedClass)

                      // determine the next selected item based on the key pressed (up/down)
                      let nextSelectedItem
                      switch (e.keyCode) {
                          case KEY_UP:

                              // get the next selected list item
                              nextSelectedItem = selectedListItem ?
                                  selectedListItem.previousSibling :
                                  suggestionsListElem.lastChild

                              break
                          case KEY_DOWN:

                              // get the next selected list item
                              nextSelectedItem = selectedListItem ?
                                  selectedListItem.nextSibling :
                                  suggestionsListElem.firstChild

                              break
                      }

                      selectSuggestion(nextSelectedItem)

                      // also, set the input value property without updating inputValue
                      // otherwise, reset input value to what it originally was (inputValue)
                      nextSelectedItem ?
                          this.value = nextSelectedItem.querySelector("a").getAttribute("data-value") :
                          this.value = inputValue

                  }

                  break

              case KEY_RETURN:

                  // on return, form subsission, we'll just close the popup
                  options.closeHandler()

                  break

              case KEY_ESCAPE:

                  inputElem.value = inputValue

                  // on return, form subsission, we'll just close the popup
                  options.closeHandler()

                  break

              default:

                  // check if value has changed to do another suggestion lookup
                  if (this.value != inputValue) {

                      inputValue = this.value

                      // a timeout will ensure that we only call the suggest api when it appears that
                      // the user has finished typing.
                      clearTimeout(inputTimeout)
                      inputTimeout = setTimeout(function() {

                          // send request to funnelback to get results and update order of courses
                          // this is instead of using jQuery.ajax which we don't want to depend on here
                          // TODO better handle url here - it should load into URLQueryParams and update 
                          //   partial_q or create it as we shouldn't assume the client has been give the 
                          //   correct url e.g. suggestUrl: http://..?partial_query=whyamihere
                          getJsonpResponse(
                              `${options.suggestUrl}&partial_query=${inputValue}`,
                              {},
                              suggestions => {

                              // remove duplicates
                              suggestions = (function(arr) {
                                  return arr.filter((e,i)=> arr.indexOf(e) >= i)
                              })(suggestions)

                              // insert push words if not there already 
                              suggestions = $this.mergePushWords(inputValue, suggestions)

                              // don't show suggestions if less than 1 or, if 1, don't show if
                              // the only suggestion is the same as what's in the input field
                              if (suggestions.length == 0) {
                                  options.closeHandler()
                              }else {
                                  options.openHandler()
                              }

                              // build html
                              let html = []
                              for (let suggestionValue of suggestions.slice(0, options.limit)) {
                                
                                  // we want the text to bold the suggestion part e.g. hist<strong>ory<strong>
                                  const regex = new RegExp('^'+inputValue+'(.*?)', "i")
                                  let suggestionText = suggestionValue.replace(regex, inputValue+'<span style="font-weight: bold">$1<span>')

                                  html.push('<li><a href="#" data-value="'+suggestionValue+'">'+suggestionText+'</a></li>')
                              }

                              suggestionsListElem.innerHTML = html.join("") // no whitespace
                              
                              for (let link of suggestionsListElem.querySelectorAll("a")) {
                                  
                                  // attach behaviour to click event of each suggestion link
                                  link.addEventListener("click", function(e) {

                                      // update the value of input first 
                                      const value = this.getAttribute("data-value")
                                      inputElem.value = value

                                      // This may be a custom click handler 
                                      options.clickHandler(value, inputElem, suggestionsElem)

                                      // close the suggestions 
                                      options.closeHandler()

                                      e.preventDefault()
                                  })

                                  // as they rollover suggestions in the list, we'll move the selected class
                                  link.parentNode.addEventListener("mouseover", function(e) {
                                      selectSuggestion(this)
                                  })
                              }

                          })

                      }, 300)
                  }

          }

      })
  }

  /**
   * Determine whether suggestions item is currently selected 
   */
  isItemSelected() {

      // const inputElem = this.inputElem
      const suggestionsElem = this.suggestionsElem
      const options = this.options

      // if suggestions box is not visible, scroll to results
      let isSuggestionsVisibleAndEngaged = suggestionsElem.offsetWidth > 0 &&
          suggestionsElem.offsetHeight > 0 &&
          document.querySelectorAll( `.${options.selectedClass}` ).length > 0

      return isSuggestionsVisibleAndEngaged

  }

  /**
   * This will query push words with a given partial query. It also defined HOW 
   * partial queries are matched. Here it is only matched if partial query is at 
   * the beginning of the push word
   * @param {str} partialQuery 
   * @returns {Array<string>}
   */
  getPushWords(partialQuery) {
    return typeof partialQuery === 'string' && partialQuery.length > 0 ? 
        this.options.pushWords.filter(word => this.options.getPushWordsMatcher(partialQuery, word)) :
        []
  }

  /**
   * This will take a partial query and merge the push words that match it with the 
   * suggestions from the suggest api. 
   * @param {str} partialQuery 
   * @returns {Array<string>}
   */
  mergePushWords(partialQuery, suggestions) {
    const pushWords = this.getPushWords(partialQuery).filter(word => suggestions.indexOf(word) === -1) // remove duplicates 
    return this.options.mergePushWordsCallback(pushWords, suggestions)
  }

  /**
   * Close this suggestions box
   */
  close() {
    this.options.closeHandler()
  }

  /**
   * Close this suggestions box
   */
  open() {
    this.options.openHandler()
  }
}