const DEVELOPMENT = "development";
// const STAGING = "staging";
const PRODUCTION = "production";

var name;
switch (window.location.hostname) {
    case "localhost":
    case "t4-test.is.strath.ac.uk":
    case "new-t4-test.is.strath.ac.uk":
        name = DEVELOPMENT;
        break;
    // case "t4zone.is.strath.ac.uk":
    //     name = STAGING;
    //     break;

    // production should always be the default encase hostname value is not found.
    // if so, we might mess up dev and staging environments, but never production.
    default:
        name = PRODUCTION;
}

// export these which can be used with getEnvironmentName e.g. getEnvironmentName() === STAGING
export {
   DEVELOPMENT,
//    STAGING,
   PRODUCTION
};

/**
 * Return the name of the environment
 */
export const getEnvironmentName = () => {
    return name;
}

/**
 * Human readable function to check if on production environment
 */
export const isProduction = () => {
    return name === PRODUCTION;
}

/**
 * Human readable function to check if on production environment
 */
export const isDevelopment = () => {
    return name === DEVELOPMENT;
}
