import Suggestions from "./modules/searchpages/Suggestions";

import { isProduction } from "./modules/env/functions";

import * as pushwords from "./modules/searchpages/constants/pushwords";

const { detect } = require("detect-browser");
const browser = detect();

// we do a bit of browser sniffing on this page there are features such as suggestions that
// ie doesn't get as it doesn't support current js standards, however it does still need to
// function as a search.

// suggestions
switch (browser.name) {
  case "ie": // add browsers here if needed
    // ie doesn't get suggestions for now (unsupported)

    // provide a fallback form action for ie...

    const searchForm = document.getElementById("HPCourseSearchfrm");
    const searchInputForm = document.getElementById("HPCourseSearch");

    // change form action to point at the non-js search
    searchForm.setAttribute(
      "action",
      "https://fback-sp.is.strath.ac.uk/s/search.html"
    );

    // we also need to change the name of the input as this is different between
    // funnelback's 'query' and all places throughout this site that use 'term'
    searchInputForm.setAttribute("name", "query");

    break;

  default:
    const inputElem = document.getElementById("HPCourseSearch");
    const suggestionsElem = document.getElementById(
      "hp-course-search__suggestions"
    );

    // Create an instance of the Suggestions which will apply
    const suggestions = new Suggestions(inputElem, suggestionsElem, {
      suggestUrl: isProduction()
        ? `https://fback-sp.is.strath.ac.uk/s/suggest.json?collection=uos-courses&profile=_default_preview`
        : `https://fback-dsp.is.strath.ac.uk/s/suggest.json?collection=uos-courses-xml&profile=_default_preview`,
      pushWords: pushwords.courses,
      clickHandler: (query, inputElem, suggestionsElem) => {
        inputElem.value = query;
      },
    }); 
}
