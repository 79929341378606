import {
  FILTER_OPERATOR__AND,
  FILTER_OPERATOR__OR,
  FILTER_OPERATOR__AND_OR,
} from "./constants/operators";

/**
 * Will get a string property from an object if exists, or return a default if undefined
 * Note: If the property exists with a null value, it will return that null regardless of custom default
 * Also, this function by default will clean up values (e.g. strings will be trimmed)
 * @param {string} key
 * @param {object} obj
 */
export const getObjectProperty = (key, obj, defaultValue) => {
  var returnValue = obj && obj[key];
  if (typeof returnValue === "string") returnValue = returnValue.trim();
  return typeof returnValue !== "undefined" ? returnValue : defaultValue;
};

/**
 * Will get a string property from an object if exists, or return a default if null or undefined
 * @param {string} key
 * @param {object} obj
 */
export const getArrayFromObjectCSVProperty = (
  key,
  obj,
  defaultValue,
  delimeter = ","
) => {
  var returnValue = obj && obj[key];
  if (typeof returnValue === "string") {
    if (returnValue.length > 0) {
      returnValue = obj[key].split(delimeter);
      returnValue = returnValue
        .map((item) => item.trim())
        .filter((item) => item !== ""); // filter out empty strings
    } else {
      returnValue = []; // empty string? empty array, which is not how split works
    }
  } else {
    returnValue = defaultValue;
  }

  return returnValue;
};

/**
 * Generate a simple non-secure hash for generating IDs
 * @param {string} string The string to convert to a hash
 */
export const stringToHash = (string) => {
  var hash = 0;
  if (string.length == 0) return hash;
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};

// RESULTS functions

/**
 * Get filterNames from activeFilters that have values
 * @param activeFilters {object}
 * @return {array}
 */
export const getFilterNamesFromActiveFilters = (activeFilters) => {
  // TODO rename to getFilterNamesNotEmptyFromActiveFilters

  // reduce filterNames to only active filters with values
  let filterNames = Object.keys(activeFilters).filter((filterName) => {
    return activeFilters[filterName].length > 0;
  });

  return filterNames;
};

/**
 * Results filtered against active filters (e.g. checked checkboxes)
 * Having this as a method with arguments and return value allows us
 * to manage some forms of unit testing.
 * @param results {array<object>}
 * @param activeFilters {object}
 * @param filterOperator {array<object>}
 * @param filterNames {array<string>}
 * @return {array<objects>}
 */
export const getFilteredResults = (results, activeFilters, filterOperator) => {
  return results.filter((result) =>
    isResultValid(result, activeFilters, filterOperator)
  );
};

/**
 * Will compare a result against active filters and filter operator (e.g. FILTER_OPERATOR__*)
 * and return boolean if valid
 * @param {object} result
 * @param {object} activeFilters
 * @param {integer} filterOperator
 * @returns {boolean}
 */
export const isResultValid = (
  result,
  activeFilters,
  filterOperator,
  filterOptions = {}
) => {
  // if no filters are active, then this result by default is valid
  let filterNames = getFilterNamesFromActiveFilters(activeFilters);
  if (filterNames.length === 0) return true;

  // depending on the filter operator will determine how we set
  // returnValue. For example, if filterOperator is _AND_OR then
  // we're drop out when we encounter a single non-pass. However,
  // _OR_AND will default to false and assign true when it meets a
  // single filter value. Gives us a lot more flexibility.
  let returnValue;
  switch (filterOperator) {
    case FILTER_OPERATOR__AND:
      returnValue = true;
      break;
    case FILTER_OPERATOR__OR: // TODO
      returnValue = false;
      break;
  }

  // note: we need to return a value to filter() so don't use forEach here
  // TODO for (var filterName in activeFilters).. change above too
  groups: for (let i = 0; i < filterNames.length; i++) {
    let filterName = filterNames[i];

    // we need to reset this on every group iteration
    if (filterOperator === FILTER_OPERATOR__AND_OR) {
      returnValue = false;
    }

    for (let j = 0; j < activeFilters[filterName].length; j++) {
      let filterValue = activeFilters[filterName][j];

      // metaData[filterName] can be a string, so we'll make an array in such case
      let metaDataValueAsArray = result.metaData[filterName];
      if (!Array.isArray(metaDataValueAsArray)) {
        metaDataValueAsArray = [metaDataValueAsArray];
      }

      // create a temporary array with lowercase values is case is insensitive
      if (
        typeof filterOptions != "undefined" &&
        filterOptions.matchCase === false
      ) {
        // set all array items to lower case (e.g. {subject: ["architecture"]})
        metaDataValueAsArray = metaDataValueAsArray.map(function (value) {
          return value.toLowerCase();
        });

        // set filterValue to lower case too e.g. "architecture"
        filterValue = filterValue.toLowerCase();
      }

      // again here, we set returnValue differently whether
      // _AND or _OR oprators. If this is _ALL then we want to
      // to loop through all values and all names
      if (filterOperator === FILTER_OPERATOR__AND) {
        if (metaDataValueAsArray.indexOf(filterValue) < 0) {
          // not found
          returnValue = false;
          break groups;
        }
      } else if (filterOperator === FILTER_OPERATOR__OR) {
        if (metaDataValueAsArray.indexOf(filterValue) > -1) {
          // is found
          returnValue = true;
          break groups;
        }
      } else if (filterOperator === FILTER_OPERATOR__AND_OR) {
        if (metaDataValueAsArray.indexOf(filterValue) > -1) {
          // is found
          returnValue = true;
          continue groups; // will exit on true if last `group` iteration
        }
      }
    }

    // if after looping through this groups values and nothing found, this this
    // group fails and this is not ok for AND_OR
    if (filterOperator === FILTER_OPERATOR__AND_OR && !returnValue) {
      break groups;
    }
  }

  return returnValue;
};

/**
 * Will get an object of arrays of values
 * @param {Array} results
 * @param {Array} filterNames e.g. ["level", "subject"]
 * @returns {object} e.g. { \level: ["Undergraduate", ..], subject: ["Maths", ..]}
 */
export const getAvailableFilterValuesFromResults = (results, filterNames) => {
  var availableFilterValues = {};
  for (let filterName of filterNames) availableFilterValues[filterName] = [];

  // loop all results and build up object/arrays of available filters
  for (let result of results) {
    for (let filterName of filterNames) {
      // check metaData field exists
      if (typeof result.metaData[filterName] !== "undefined") {
        if (Array.isArray(result.metaData[filterName])) {
          for (let value of result.metaData[filterName]) {
            if (availableFilterValues[filterName].indexOf(value) < 0) {
              availableFilterValues[filterName].push(value);
            }
          }

          // put into alphabetically order
          availableFilterValues[filterName].sort();
        } else {
          const value = result.metaData[filterName];
          if (
            availableFilterValues[filterName].indexOf(value) < 0 &&
            value !== ""
          )
            availableFilterValues[filterName].push(value);
        }
      }
    }
  }

  return availableFilterValues;
};

/**
 * Will build object of value/count objects (value keys e.g. {\Undergraduate: n} )
 * @param {Array} results
 * @param {object} activeFilters
 * @param {object} availableFilterValues
 * @param {integer} filterOperator
 * @param {object} filterOptions
 * @returns {object} e.g. { \level: {\Undergraduate: 22, ..}, subject: {\Maths: 42}, ..}}
 */
export const getAvailableFiltersWithTotals = (
  results,
  activeFilters,
  availableFilterValues,
  filterOperator
) => {
  // build starting object and we'll build up counts in the loops
  var availableFiltersWithTotals = {};

  // loop through each result, loop through each value and check with isResultValid
  // it's a lot of looping but only real way to determine the count of a value being added
  // to active filters.
  for (var filterName in availableFilterValues) {
    // create filterName in availableFiltersWithTotals if none exist
    availableFiltersWithTotals[filterName] = {};

    for (let value of availableFilterValues[filterName]) {
      // create zero starting point. the true from isResultValid will
      // increment this later.
      availableFiltersWithTotals[filterName][value] = 0;

      // for this value, we'll loop through each result and calculate count
      // should that filter be selected (e.g. activeFilters + value)
      for (let result of results) {
        // check wheth value exists already in activeFilters
        let newActiveFilters = JSON.parse(JSON.stringify(activeFilters));
        newActiveFilters[filterName] = newActiveFilters[filterName] || [];
        if (newActiveFilters[filterName].indexOf(value) < 0)
          newActiveFilters[filterName].push(value);

        if (isResultValid(result, newActiveFilters, filterOperator)) {
          availableFiltersWithTotals[filterName][value]++;
        }
      }
    }
  }

  return availableFiltersWithTotals;
};

/**
 * Will remove values from activeFilters that are missing from availableFilterValues
 * @param {object} activeFilters e.g. { \level: [..], subject: [..], ..}
 * @param {object} availableFilterValues e.g. { \level: [..], subject: [..], ..}
 * @param {object} filterOptions matchCase:boolean,
 * @returns void
 */
export var updateActiveFiltersFromAvailableFilterValues = (
  activeFilters,
  availableFilterValues,
  filterOptions = {}
) => {
  for (let filterName in activeFilters) {
    // if matchCase is false then we'll change the values of activeFilters to the correct case
    // this means that it won't drop out in the array filter next. It means we don't have to
    // scatter lots of toLowerCase around the js, so the rest of the stack from here is strictly
    // case sensitive but we've corrected case here.
    if (filterOptions.matchCase === false) {
      activeFilters[filterName] = activeFilters[filterName].map((af) => {
        let foundIndex = availableFilterValues[filterName].findIndex(
          (v) => v.toLowerCase() === af.toLowerCase()
        );
        if (foundIndex > -1) {
          return availableFilterValues[filterName][foundIndex];
        }
      });
    }

    // this will do a comparison for any active filters that are not in this availableFilters
    activeFilters[filterName] = activeFilters[filterName].filter((value) => {
      return availableFilterValues[filterName].indexOf(value) > -1;
    });
  }
};

/**
 * @param {object} activeFilters
 * @param {string} urlQueryString
 * @return {URLSearchParams}
 */
export var createURLSearchParamsFromActiveFilters = (
  activeFilters,
  urlQueryString,
  separator = ","
) => {
  urlQueryString =
    typeof urlQueryString === "string"
      ? urlQueryString
      : window.location.search;
  var newParams = new URLSearchParams(urlQueryString);

  for (let name of Object.keys(activeFilters)) {
    activeFilters[name].length
      ? newParams.set(name, activeFilters[name].join(separator))
      : newParams.delete(name);
  }

  return newParams;
};

/**
 * create some human readable metaData props (e.g. "L" -> "level") for ALL results
 * note: this ought to come here as filteredResults is done before $watch and
 *   we probably want these human readable properties so we can work with them
 *   in filteredResults function - so here is best as only needs done once inbetween
 * @param {Array<object>} results
 * @param {object} metaDataMapping
 */
export var setmetaDataMappingToResults = (results, metaDataMapping) => {
  if (results.length) {
    var filterNames = Object.keys(metaDataMapping);

    for (let result of results) {
      // set human-readable names (eg. metaData.L -> metaData.level)
      for (const filterName of filterNames) {
        result.metaData[filterName] = metaDataMapping[filterName](result);
      }
    }
  }
};

// UTIL functions

/**
 * Sort an object by its keys
 * @param {object} obj
 * @return {object} obj
 */
export var sortObjectKeys = function (unsorted) {
  var sorted = {};
  var sortedKeys = Object.keys(unsorted).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );

  for (let key of sortedKeys) {
    sorted[key] = unsorted[key];
  }

  return sorted;
};

/**
 * Will strip tags and attach highlighting to search snippets
 * @param {string} summary
 * @param {integer} summaryLimit Length before cropping
 */
export var truncateHtmlString = (summary, summaryLimit) => {
  return summary.replace(/<(?:.|\n)*?>/gm, "").substring(0, summaryLimit);
};

/**
 * Will render an array as "'One', 'Two' and 'three'" or "'One' and 'two'" or just "'One'"
 */
export const getListStringFromArray = (parts) => {
  var listString;
  if (parts.length === 0) {
    listString = "";
  } else if (parts.length === 1) {
    listString = "'" + parts[0] + "'";
  } else {
    // more than 1
    var lastPart = parts.pop();
    listString = "'" + parts.join("', '") + "' and '" + lastPart + "'";
  }
  return listString;
};

// HTML functions

/**
 * Make a jsonp request to a server resource
 * This is instead of using jQuery.ajax
 * @param {string} url
 * @param {object} params To be converted to e.g. n1=v1&n2=v2
 * @param {function} callback What to do the the data, should accept data param
 * @param {dom} appender Where we'll append the script to, useful for testing
 */
export var getJsonpResponse = function (
  url,
  params,
  callback,
  appender = document.querySelector("head")
) {
  // Create <script> object
  var script = document.createElement("script");

  // build the url with params
  script.src = (function (url, params = {}) {
    // e.g. params.callback=jsonpcallback_hjhz7
    if (typeof params.callback === "undefined")
      params.callback =
        "jsonpcallback_" + Math.random().toString(36).substring(7);

    // generate param string e.g. n1=v1&n2=v2
    var paramString = Object.keys(params)
      .map((key) => {
        return key + "=" + encodeURIComponent(params[key]);
      })
      .join("&");

    // affix urlParams string on the end of url
    if (paramString) {
      if (url.indexOf("?") > 0) {
        // contains ?
        if (url.indexOf("?") === 0 || url.indexOf("&") === 0) {
          url += paramString;
        } else {
          url += "&" + paramString;
        }
      } else {
        url += "?" + paramString;
      }
    }

    return url;
  })(url, params);
  //
  // handler callback
  window[params.callback] = function (data) {
    // developer defined callback
    callback(data);

    // tidy up!
    // allow the function to be garbage collected, tidy up window, and
    // remove the script from the dom
    window[params.callback] = null && delete window[params.callback];
    script.parentNode.removeChild(script);
  };

  // append the script to run it
  appender.appendChild(script);
};

// Helper functions

/**
 * Initiate infinite scrolling on search results
 * @param {SearchResults} searchResults This is the instance of SearchResults.js
 * @param {DOMElem} scrollPointElem This is the element whose top coord will trigger a infinite scroll (more results)
 */
export const initInfiniteScrolling = (
  searchResults,
  scrollPointElem,
  options = {}
) => {
  // set defaults
  options = {
    // This is the initial results to show when the page loads
    resultsToShowStart: 10,

    // This is the number of results to increment on each scroll
    resultsToShowScrolling: 10,

    ...options,
  };

  // init sate with start results to show
  searchResults.setState({
    resultsToShow: options.resultsToShowStart,
  });

  // when results change, or activeFilters we'll reset resultsToShow
  searchResults.onStateChange(
    ["results", "activeFilters"],
    function (state, settings) {
      searchResults.setState({
        resultsToShow: options.resultsToShowStart,
      });
    }
  );

  // this will control our infinite scrolling. when the user scrolls they'll
  // increment resultsToShow which will refresh resultsHtml
  window.addEventListener("scroll", (event) => {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollBottom = scrollTop + height;

    // calculate position of footer on page. we'll use this to determine whether
    // we need to load more courses
    let scrollPoint = (() => {
      let bodyRect = document.body.getBoundingClientRect();
      let footerRect = scrollPointElem.getBoundingClientRect(); // TODO
      return footerRect.top - bodyRect.top;
    })();

    // results to show is the current number of courses on display
    let resultsToShow = searchResults.state.resultsToShow;

    // if scrollBottom has exceeded the top of the footer.. it's infinite scroll time, baby!
    if (scrollBottom > scrollPoint) {
      resultsToShow += options.resultsToShowScrolling;
      searchResults.setState({
        resultsToShow: resultsToShow,
      });
    }
  });
};

/**
 * Initiate infinite scrolling on search results
 * @param {SearchResults} searchResults This is the instance of SearchResults.js
 * @param {DOMElem} dataElem This is the element that has the data-filter-* elems
 */
export const initActiveFiltersFromDataAttributes = (
  searchResults,
  dataElem
) => {
  searchResults.setState({
    activeFilters: (function () {
      // TODO share me
      let activeFilters = searchResults.state.activeFilters;

      for (let key of searchResults.settings.filters) {
        let dataFilterValue = dataElem.getAttribute("data-filter-" + key);
        if (dataFilterValue !== null && dataFilterValue !== "") {
          if (activeFilters[key].indexOf(dataFilterValue) === -1) {
            activeFilters[key].push(dataFilterValue);
          }
        }
      }

      return activeFilters;
    })(),
  });
};

/**
 * When filters change, this'll write them to the url query params e.g. ?level=ug&...
 * @param {SearchResults} searchResults
 */
export const initUrlQueryParamsOnStateChange = (searchResults) => {
  searchResults.onStateChange("urlParams", function (state, settings) {
    if (state.urlParams.toString() !== "") {
      history.replaceState(
        null,
        null,
        window.location.pathname + "?" + state.urlParams.toString()
      ); // toString -> n=v&n2=v2,v3&...
    } else {
      history.replaceState(null, null, window.location.pathname);
    }
  });
};

export const renderSearchInfoText = (
  searchInfoElem,
  searchResults,
  filteredResults,
  renderer
) => {
  const state = searchResults.state;
  const activeFilters = state.activeFilters;

  searchInfoElem.innerHTML = (() => {
    // parts is the query and any filters applied that might alter results
    // this ought to be fomatted as "part, part, .. and part".
    let parts = [];

    if (state.query) {
      parts.push(state.query.trim());
    }

    // loop through each filter and add it to the end e.g. & UG & Part time & ...
    for (let name in activeFilters)
      for (let value of activeFilters[name]) parts.push(value);

    return renderer(filteredResults.length, parts);
  })();
};

// Render the html for the level checkboxes
export const renderFilterCheckboxGroup = (
  searchResults,
  checkboxWrapperElem,
  filterName,
  filterValues,
  options = {}
) => {
  const results = searchResults.state.results;
  const availableFilterValues = searchResults.state.availableFilterValues;
  const activeFilters = searchResults.state.activeFilters;
  const filterOperator = searchResults.settings.filterOperator;

  // we can calculate totals for each filter (should they be selected and
  // combined with the current active filters to get totals). Also, unlike
  // availableFilterValues, this will change when active filters changes
  // so needs to be done during state change.
  let availableFilterWithTotals = getAvailableFiltersWithTotals(
    results,
    activeFilters,
    availableFilterValues,
    filterOperator
  );

  // in some cases we want to strictly define the order of checkboxes, so values might be passsed in
  // in other case, we just want to use the values from all available
  if (!filterValues) {
    filterValues = Object.keys(availableFilterWithTotals[filterName]);
  }

  // @return boolean
  const isFilterDisabled = (value) =>
    typeof availableFilterWithTotals[filterName][value] === "undefined";

  // @return int
  const getFilterTotal = (value) =>
    availableFilterWithTotals[filterName][value] || 0;

  checkboxWrapperElem.innerHTML = `
        ${filterValues
          .map(
            (value) => `
            <div class="${options.checkbox_class}">
                <input type="checkbox" name="${filterName}" value="${value}" id="checkbox_${stringToHash(
              value
            )}" ${
              activeFilters[filterName].indexOf(value) > -1
                ? 'checked="checked"'
                : ""
            } ${isFilterDisabled(value) ? 'disabled="disabled"' : ""}>
                <label for="checkbox_${stringToHash(value)}" ${
              isFilterDisabled(value) ? "data-disabled" : ""
            }> ${value} <!-- sup>${getFilterTotal(value)}</sup --></label>
            </div>
        `
          )
          .join("")}
        `;

  // set event handlers for when checkboxes (level and advanced options) are changed
  for (let checkbox of checkboxWrapperElem.querySelectorAll(
    "input[type='checkbox']"
  )) {
    checkbox.addEventListener("change", function (e) {
      // we'll add/remove the value from activefilters depending on the
      // checked status of the checkbox
      // let activeFilters = searchResults.state.activeFilters
      let name = checkbox.name,
        value = checkbox.value;
      if (checkbox.checked) {
        if (activeFilters[name].indexOf(value) === -1)
          activeFilters[name].push(value);
      } else {
        let index = activeFilters[name].indexOf(value);
        if (index > -1) activeFilters[name].splice(index, 1);
      }

      // even though activeFilters is referrenced, we want to trigger a
      // state change to regenerate filteredResults
      searchResults.setState({
        activeFilters: activeFilters,
        urlParams: createURLSearchParamsFromActiveFilters(
          searchResults.state.activeFilters,
          null,
          searchResults.settings.urlParamFilterSeparator
        ),
      });
    });
  }
};
