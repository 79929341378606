/**
 * These are words that are inserted into our suggestions probably because Funnelback isn't doing that for us. 
 * Sometimes push words will be shared across different scripts. For example, courses push words will be used 
 * in course search and homepage course search suggestions
 */

// Courses push words
export const courses = [
  'genealogy',
  '5g'
];
