
/**
 * this is a query we'll use instead of "" which funnelback doesn't like
 * @const NULL_QUERY {string}
 */
export const NULL_QUERY = "!padrenullquery";

/**
 * AND filters applied on all results across/within filter groups
 * e.g. level ug AND subject maths AND subject physics
 * @const FILTER_OPERATOR__AND {int}
 */
export const FILTER_OPERATOR__AND = 1;

/**
 * OR filters applied on all results across/within filter groups
 * e.g. level ug OR subject maths OR subject maths
 * @const FILTER_OPERATOR__OR {int}
 */
export const FILTER_OPERATOR__OR = 2;

/**
 * AND filters applied between filter groups OR oprator applied within filter groups
 * e.g. (level: ug OR pgt) AND (subject: maths OR physics)
 * @const FILTER_OPERATOR__AND_OR {int}
 */
export const FILTER_OPERATOR__AND_OR = 3;
