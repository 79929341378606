

/**
 * this is just a non-stopPropogation means of closing the menu when the
 * user clicks elsewhere on the page. We'll move up the dom from the event.target
 * and if we don't hit the input/suggestions container before we get to the top we'll
 * close the suggestions box
 * @param container {DOMElem} \This is the container that we're checking has been clicked outside of
 * @param clickOutsideHandler {function} \Handler for when a click is outside container
 */
export const addClickOutsideContainerHandler = (container, clickOutsideHandler) => {

    document.addEventListener("click", function(event) {

        // determine whether we're within the suggestions box
        var el = event.target;
        var isClickOutside = true;
        while (el) {
            if (el == container) isClickOutside = false;
            el = el.parentNode;
        }

        // use the value of isClickOutside
        if (isClickOutside) clickOutsideHandler();

    });
};





/**
 * This can be used to ensure that other popup dialogues are closed when only
 * one popup should be open at one time. For example, we wouldn't want the site
 * search popup open at the same time as course search suggestions
 */
export const popupManager = (() => {

    /**
     * This is a collection of handlers for opening popups by name
     */
    var openHandlers = {};

    /**
     * This is a collection of handlers for closing popups by name
     */
    var closeHandlers = {};

    /**
     * Register the handler for closing a popup
     */
    var _registerOpenHandler = (name, handler) => {
        openHandlers[name] = handler;
    };

    /**
     * Register the handler for closing a popup
     */
    var _registerCloseHandler = (name, handler) => {
        closeHandlers[name] = handler;
    };

    var _openPopup = (name) => {

        // cycle through each close handler and close any widgets that are not
        // the current one
        for (var key in closeHandlers) {
            if (key !== name) {
                closeHandlers[key]();
            }
        }

        openHandlers[name]();
    };

    var _closePopup = (name) => {
        closeHandlers[name]();
    };

    return {
        openPopup: _openPopup,
        closePopup: _closePopup,
        registerOpenHandler: _registerOpenHandler,
        registerCloseHandler: _registerCloseHandler
    }

})();
